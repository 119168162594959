import React from 'react';

import { useSelector } from 'react-redux';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { useCompanyVersion } from '../CompanyVersion';

export function useImportantActions({ ignore } = {}) {
	const version = useCompanyVersion();
	const errorId = useSelector((store) => {
		const object = InvoiceStore.selectors.batches(store, {
			status: 'ERROR',
		})?.[0];

		return object?._id ?? object?.id;
	});

	const batchID = useSelector((store) => {
		const objects = InvoiceStore.selectors.batches(store, {
			status: 'SENT',
		})?.[0];

		return objects?._id ?? objects?.id;
	});

	const ignoreErrorBatch = ignore?.includes?.('errorBatch');

	return React.useMemo(() => {
		const actions = [];

		if (errorId && !ignoreErrorBatch) {
			actions.push({
				id: 'errorBatch',
				variant: 'link',
				path: `/invoices`,
			});
		}

		if (batchID && version === 1) {
			actions.push({
				id: 'batch',
				variant: 'link',
				path: `/invoices/batch/${batchID}`,
			});
		}

		return actions;
	}, [batchID, errorId, version, ignoreErrorBatch]);
}
