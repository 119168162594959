import React from 'react';

import { useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import Table, {
	TableCell,
	TableHeader,
	TableRow,
} from '@asteria/component-core/table';
import { Text } from '@asteria/component-core/typography';

import { useFormValues } from '@asteria/component-form';
import { Switch } from '@asteria/component-form-v2';
import Input from '@asteria/component-form/input';
import Select, { Option } from '@asteria/component-form/select';

import * as AppStore from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import InputReset from '../../InputReset';
import { useClient } from '../hooks';

import './index.scss';

const ClientDetailsReferenceRow = React.memo((props) => {
	const { field, index, name, client, remove } = props;

	const onRemove = React.useCallback(() => remove(index), [index, remove]);

	return (
		<Group direction="horizontal" verticalAlign="center">
			<Select
				key={field.id}
				name={[name, index, 'value'].join('.')}
				label={TranslationService.get(
					[
						'client.overview.details.service.delivery.reference',
						`client.overview.details.${client?.type}.service.delivery.reference`,
					],
					undefined,
					{ client: client, index: index + 1 },
				)}
				direction="horizontal"
			>
				<Option value="BuyerReference">
					{TranslationService.get(
						[
							'client.overview.details.service.delivery.reference.option.BuyerReference',
							`client.overview.details.${client?.type}.service.delivery.reference.option.BuyerReference`,
						],
						undefined,
						{ client: client, index: index + 1 },
					)}
				</Option>
				<Option value="BuyerOrderId">
					{TranslationService.get(
						[
							'client.overview.details.service.delivery.reference.option.BuyerOrderId',
							`client.overview.details.${client?.type}.service.delivery.reference.option.BuyerOrderId`,
						],
						undefined,
						{ client: client, index: index + 1 },
					)}
				</Option>
				<Option value="SellerOrderNo">
					{TranslationService.get(
						[
							'client.overview.details.service.delivery.reference.option.SellerOrderNo',
							`client.overview.details.${client?.type}.service.delivery.reference.option.SellerOrderNo`,
						],
						undefined,
						{ client: client, index: index + 1 },
					)}
				</Option>
				<Option value="DeliveryOrderId">
					{TranslationService.get(
						[
							'client.overview.details.service.delivery.reference.option.DeliveryOrderId',
							`client.overview.details.${client?.type}.service.delivery.reference.option.DeliveryOrderId`,
						],
						undefined,
						{ client: client, index: index + 1 },
					)}
				</Option>
			</Select>
			<Button variant="link" icon="remove" onClick={onRemove} />
		</Group>
	);
});

ClientDetailsReferenceRow.displayName = 'ClientDetailsReferenceRow';
ClientDetailsReferenceRow.propTypes = {
	field: PropTypes.object,
	name: PropTypes.string,
	remove: PropTypes.func,
	index: PropTypes.number,
	client: PropTypes.object,
};

const ClientDetailsReference = React.memo((props) => {
	const { name, client } = props;

	const { append, fields, remove } = useFieldArray({ name: name });

	const onAdd = React.useCallback(() => {
		append({});
	}, [append]);

	return (
		<Group className="asteria-component__client-reference">
			{fields.map((field, index) => (
				<ClientDetailsReferenceRow
					key={field.id}
					field={field}
					index={index}
					name={name}
					client={client}
					remove={remove}
				/>
			))}
			{fields.length < 2 ? (
				<Button
					variant="link"
					label={TranslationService.get(
						[
							'client.overview.details.service.delivery.reference.create',
							`client.overview.details.${client?.type}.service.delivery.reference.create`,
						],
						undefined,
						{ client: client },
					)}
					onClick={onAdd}
				/>
			) : null}
		</Group>
	);
});

ClientDetailsReference.displayName = 'ClientDetailsReference';
ClientDetailsReference.propTypes = {
	name: PropTypes.string,
	client: PropTypes.object,
};

const ClientEditInput = React.memo((props) => {
	const {
		type,
		name,
		required,
		disabled: $disabled,
		options,
		postfix,
		useLabel = () => props?.label,
		useVisibility = () => true,
		useDisabled = () => $disabled,

		onAction,
		onSubmit,
	} = props;

	const form = useFormValues();
	const label = useLabel(form, { onAction, onSubmit });
	const visible = useVisibility(form, { onAction, onSubmit });
	const disabled = useDisabled(form, {
		onAction,
		onSubmit,
		defaultValue: $disabled,
	});

	if (!visible) {
		return null;
	}

	if (type === 'select') {
		return (
			<Select
				name={['service', 'client', name].join('.')}
				label={label}
				direction="horizontal"
				required={required}
				disabled={disabled}
			>
				{(options ?? []).map(({ value, label }, index) => (
					<Option key={[value, index].join('-')} value={value}>
						{label}
					</Option>
				))}
			</Select>
		);
	}

	if (type === 'switch') {
		return (
			<Switch
				name={['service', 'client', name].join('.')}
				label={{ label, position: 'left' }}
				required={required}
				disabled={disabled}
				postfix={postfix}
			/>
		);
	}

	return (
		<Input
			name={['service', 'client', name].join('.')}
			label={label}
			direction="horizontal"
			required={required}
			disabled={disabled}
		/>
	);
});

ClientEditInput.displayName = 'ClientEditInput';
ClientEditInput.propTypes = {
	type: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	options: PropTypes.arrayOf(
		PropTypes.shape({ value: PropTypes.any, label: PropTypes.string }),
	),
	postfix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

	useLabel: PropTypes.func,
	useVisibility: PropTypes.func,
	useDisabled: PropTypes.func,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

const ClientDetailsOverview = React.memo((props) => {
	const { className, client, onAction, onSubmit } = props;

	const method = useFormValues({ name: 'service.client.delivery.method' });

	const fields = useClient(client).reduce(
		(acc, object) => {
			// if (method !== 'EMAIL') {
			// 	if (object?.key === 'contact.email') {
			// 		return acc;
			// 	}
			// }

			if (object?.fields) {
				acc.table.push(object);
			} else {
				acc.regular.push(object);
			}

			return acc;
		},
		{ regular: [], table: [] },
	);

	const companyService = useSelector((store) =>
		AppStore.selectors
			.company(store)
			?.service?.data?.service?.toUpperCase?.(),
	);

	const enabledDeliveryMethods = useSelector(
		(store) => AppStore.selectors.company(store)?.service?.data?.delivery,
	);

	return (
		<div
			className={cn(
				'asteria-component__client-overview__edit__list',
				className,
			)}
		>
			<Group
				className={cn(
					'asteria-component__client-edit-section',
					'asteria--variant-plain',
				)}
			>
				{companyService === 'INVOICE' ? (
					<>
						<Select
							name="service.client.delivery.method"
							label={TranslationService.get(
								[
									'client.overview.details.service.delivery.method',
									`client.overview.details.${client?.type}.service.delivery.method`,
								],
								undefined,
								{ client: client },
							)}
							placeholder={TranslationService.get(
								[
									'client.overview.details.service.delivery.method.placeholder',
									`client.overview.details.${client?.type}.service.delivery.method.placeholder`,
								],
								undefined,
								{ client: client },
							)}
							direction="horizontal"
							required
						>
							<Option value="PAPER">
								{TranslationService.get(
									[
										'client.overview.details.service.delivery.method.value.PAPER',
										`client.overview.details.${client?.type}.service.delivery.method.value.PAPER`,
									],
									undefined,
									{ client: client },
								)}
							</Option>
							<Option value="EMAIL">
								{TranslationService.get(
									[
										'client.overview.details.service.delivery.method.value.EMAIL',
										`client.overview.details.${client?.type}.service.delivery.method.value.EMAIL`,
									],
									undefined,
									{ client: client },
								)}
							</Option>

							{enabledDeliveryMethods?.edi ? (
								<Option value="ELECTRONIC">
									{TranslationService.get(
										[
											'client.overview.details.service.delivery.method.value.ELECTRONIC',
											`client.overview.details.${client?.type}.service.delivery.method.value.ELECTRONIC`,
										],
										undefined,
										{ client: client },
									)}
								</Option>
							) : null}

							{enabledDeliveryMethods?.archived ? (
								<Option value="ARCHIVE">
									{TranslationService.get(
										[
											'client.overview.details.service.delivery.method.value.ARCHIVE',
											`client.overview.details.${client?.type}.service.delivery.method.value.ARCHIVE`,
										],
										undefined,
										{ client: client },
									)}
								</Option>
							) : null}
						</Select>
						{method === 'ELECTRONIC'
							? [
									<Input
										key="VAN"
										required
										name="service.client.delivery.van"
										label={TranslationService.get(
											[
												'client.overview.details.service.delivery.van',
												`client.overview.details.${client?.type}.service.delivery.van`,
											],
											undefined,
											{ client: client },
										)}
										direction="horizontal"
									/>,
									<Input
										key="BuyerId"
										required
										name="service.client.delivery.buyerId"
										label={TranslationService.get(
											[
												'client.overview.details.service.delivery.buyerId',
												`client.overview.details.${client?.type}.service.delivery.buyerId`,
											],
											undefined,
											{ client: client },
										)}
										direction="horizontal"
									/>,
									<ClientDetailsReference
										key="Reference"
										name="service.client.delivery.reference"
										client={client}
									/>,
							  ]
							: null}
					</>
				) : null}

				{fields.regular.map((args, index) => (
					<ClientEditInput
						key={args?.name ?? index}
						{...args}
						onAction={onAction}
						onSubmit={onSubmit}
					/>
				))}
			</Group>

			{fields.table.length ? (
				<Table
					className={cn(
						'asteria-component__client-edit-section',
						'asteria--variant-table',
					)}
				>
					<TableHeader>
						<TableCell></TableCell>
						<TableCell>
							<Text>
								{TranslationService.get([
									'client.overview.details.table.header.label',
									'client.overview.details.table.header.general.label',
								])}
							</Text>
						</TableCell>
						<TableCell>
							<Text>
								{TranslationService.get([
									'client.overview.details.table.header.label',
									'client.overview.details.table.header.billing.label',
								])}
							</Text>
						</TableCell>
						<TableCell>
							<Text>
								{TranslationService.get([
									'client.overview.details.table.header.label',
									'client.overview.details.table.header.shipping.label',
								])}
							</Text>
						</TableCell>
					</TableHeader>
					{fields.table.map((object) => (
						<TableRow key={object?.key}>
							<TableCell>
								<Text className="asteria--variant-label">
									{object?.label}
								</Text>
							</TableCell>
							{(object?.fields ?? []).map(
								({ name, required }) => (
									<InputReset
										key={name}
										name={['service', 'client', name].join(
											'.',
										)}
										required={
											required ||
											(name === 'contact.billing.email' &&
												method === 'EMAIL')
										}
									/>
								),
							)}
						</TableRow>
					))}
				</Table>
			) : null}
		</div>
	);
});

ClientDetailsOverview.displayName = 'ClientDetailsOverview';

ClientDetailsOverview.propTypes = {
	className: PropTypes.string,
	client: PropTypes.object,

	onFilterLinkClick: PropTypes.func,

	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default ClientDetailsOverview;
