import React from 'react';

import PropTypes from 'prop-types';

import Accordion, { AccordionItem } from '@asteria/component-core/accordion';
import Button from '@asteria/component-core/button';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { findScrollingParent } from '@asteria/utils-funcs/node';
import useConfig from '@asteria/utils-hooks/useConfig';

import { ImportantActions } from '../../components/Actions';
import Support from '../../components/Support';
import LayoutContext from '../../layout/context';

import SupportHeader from './Header';
import SupportPageCountry from './PageCountry';
import SupportPageDefault from './PageDefault';
import SupportPageSuccess from './PageSuccess';
import { PAGES } from './constants';
import { initialState } from './utils';

import './styles.scss';

const SupportFAQ = React.memo(() => {
	const config = useConfig(['pages.support.faq'], { deep: true });

	const items = (config?.items ?? []).filter((object) => {
		const title = TranslationService.get(
			[].concat(object?.title).filter(Boolean),
		);

		const content = TranslationService.get(
			[].concat(object?.content).filter(Boolean),
		);

		return title || content;
	});

	const size = config?.size ?? 5;

	const [shouldShowAll, setShouldShowAll] = React.useState(
		items?.length <= size,
	);

	const onShowMore = React.useCallback(() => {
		setShouldShowAll(true);
	}, []);

	if (!items?.length) {
		return null;
	}

	const visible = shouldShowAll ? items : items.slice(0, size);

	return (
		<div className={cn('asteria-page__wrapper', 'asteria--variant-faq')}>
			<TextGroup>
				<Title size="page-title">
					{TranslationService.get(['page.support.faq.title'])}
				</Title>
				{TranslationService.get(['page.support.faq.content']) ? (
					<Translation
						translationKey="page.support.faq.content"
						Component={Text}
						size="lg"
					/>
				) : null}
			</TextGroup>
			<div className="asteria-page__section">
				<Accordion>
					{visible.map((object, index) => {
						const title = TranslationService.get(
							[].concat(object?.title).filter(Boolean),
						);

						const content = TranslationService.get(
							[].concat(object?.content).filter(Boolean),
						);

						return (
							<AccordionItem key={index} label={title}>
								<Translation
									translationKey={content}
									Component={Text}
								/>
							</AccordionItem>
						);
					})}
				</Accordion>
				{!shouldShowAll ? (
					<Button
						label={TranslationService.get([
							'page.support.faq.action.show-more.label',
						])}
						variant="link"
						onClick={onShowMore}
					/>
				) : null}
			</div>

			{shouldShowAll &&
			TranslationService.get(['page.support.faq.extra']) ? (
				<Translation
					translationKey="page.support.faq.extra"
					Component={Text}
					weight="semibold"
					className="asteria--variant-extra"
					size="lg"
				/>
			) : null}
		</div>
	);
});

SupportFAQ.displayName = 'SupportFAQ';

const SupportPage = (props) => {
	const { className } = props;

	const { onAction, onSubmit } = React.useContext(LayoutContext);

	const ref = React.useRef(null);

	const countries = useConfig('pages.support.countries', { deep: true });

	const [{ page, country, response }, dispatch] = React.useReducer(
		(state, action) => {
			switch (action?.type) {
				case 'PAGE':
					return {
						page: action?.payload?.page,
						country: action?.payload?.country,
						response: action?.payload?.response,
					};

				case 'COUNTRY':
					return {
						country:
							state?.country === action?.payload
								? null
								: action?.payload,
						page:
							state?.country === action?.payload
								? PAGES.DEFAULT
								: PAGES.COUNTRY,
					};

				case 'RESET':
					return initialState(countries);

				default:
					return state;
			}
		},
		initialState(countries),
	);

	const handleAction = React.useCallback(
		async (action, data) => {
			if (action === 'reset') {
				dispatch({ type: 'RESET' });
				return;
			}

			if (action === 'country:set') {
				dispatch({ type: 'COUNTRY', payload: data });
				return;
			}

			if (action === 'form:success') {
				dispatch({
					type: 'PAGE',
					payload: { page: PAGES.SUCCESS, response: data },
				});

				return;
			}

			if (action === 'country:chat') {
				if (window?.groove?.widget) {
					try {
						await window.groove.widget.updateSettings({
							enabled: !window.groove.widget.shim.isOpen,
						});
					} catch (err) {
						//
					}

					await window.groove.widget.toggle();
				}
			}

			return onAction?.(action, data);
		},
		[onAction],
	);

	React.useLayoutEffect(() => {
		const node = findScrollingParent(ref.current);

		if (node) {
			node.scroll({ behavior: 'smooth', top: 0 });
		}
	}, []);

	const handleBack = React.useCallback(() => {
		dispatch({ type: 'RESET' });
	}, []);

	return (
		<div
			ref={ref}
			className={cn(
				'asteria-page',
				{ 'asteria-page__details': page !== PAGES.DEFAULT },
				'asteria-page__support',
				{ 'asteria-page__support-details': page !== PAGES.DEFAULT },
				{ 'asteria-page__support-success': page === PAGES.SUCCESS },
				className,
			)}
		>
			<ImportantActions onAction={onAction} onSubmit={onSubmit} />

			{/* <SupportFAQ /> */}

			<div
				className={cn(
					'asteria-page__wrapper',
					'asteria--variant-support',
				)}
			>
				<SupportHeader
					page={page}
					country={country}
					showBack={
						(countries?.length ?? 0) > 1 || page === PAGES.SUCCESS
					}
					onBack={handleBack}
					response={response}
				/>

				{page === PAGES.DEFAULT ? (
					<SupportPageDefault
						countries={countries}
						onAction={handleAction}
						country={country}
					/>
				) : null}

				{page === PAGES.COUNTRY ? (
					<SupportPageCountry
						countries={countries}
						onAction={handleAction}
						country={country}
					/>
				) : null}

				{page === PAGES.SUCCESS ? (
					<SupportPageSuccess
						onAction={handleAction}
						response={response}
					/>
				) : null}
			</div>

			{country ? <Support hideSupport onAction={handleAction} /> : null}
		</div>
	);
};

SupportPage.displayName = 'SupportPage';

SupportPage.propTypes = { className: PropTypes.string };

export default SupportPage;
