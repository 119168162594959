import { useContext, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useQueries } from '@tanstack/react-query';
import { get } from 'lodash-es';

import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { useCompanyVersionQuery } from '../../../CompanyVersion';
import SearchContext from '../context';
import { useAmounts } from '../hooks';

export function useVisibility() {
	const { onSubmit } = useContext(SearchContext);

	const hasPxR = useCompanyVersionQuery({ gte: 2 });

	const amounts = useAmounts();

	const hasDates = useSelector(
		(store) =>
			!!InvoiceStore.selectors.filters(store, { type: 'DATE:SENT' })
				.length,
	);

	const hasClients = useSelector(
		(store) =>
			!!InvoiceStore.selectors.filters(store, { type: 'CLIENT' }).length,
	);

	const hasCurrencies = useSelector(
		(store) =>
			!!InvoiceStore.selectors.filters(store, { type: 'CURRENCY' })
				.length,
	);

	const [
		{ data: sent },
		{ data: error },
		{ data: completed },
		{ data: discard },
	] = useQueries({
		queries: ['SENT', 'ERROR', 'COMPLETED', 'DISCARD'].map((status) => ({
			queryKey: ['invoices', 'search', 'status', { status: status }],
			queryFn: async () => {
				return onSubmit?.('invoices:available', {
					filters: { services: { status: status } },
					pageFilters: { first: 1 },
					fields: `_id`,
				});
			},
			select: (response) => response?.pageInfo?.count ?? 0,

			placeholderData: 0,

			// refetchOnMount: false,
			refetchOnReconnect: false,
			refetchOnWindowFocus: false,
			retry: false,
		})),
	});

	const hasAmount = !!amounts?.length;
	const hasSent = !!sent;
	const hasError = !!error;
	const hasCompleted = !!completed;
	const hasExcluded = !!discard;

	return useMemo(
		() =>
			hasAmount ||
			hasDates ||
			hasClients ||
			hasCurrencies ||
			hasExcluded ||
			hasSent ||
			hasCompleted ||
			(hasPxR && hasError),
		[
			hasAmount,
			hasDates,
			hasClients,
			hasCurrencies,
			hasExcluded,
			hasSent,
			hasCompleted,
			hasPxR,
			hasError,
		],
	);
}

export function useSearch({ objects, search }) {
	const normalizedSearch = search?.toLowerCase?.() ?? '';

	const fields = useMemo(
		() => [
			'client.name',
			'meta.invoiceNumber',
			'meta.clientNumber',
			'meta.message',
			'sums.original.total',
			'sums.original.currency',
		],
		[],
	);

	const result = useMemo(() => {
		if (!normalizedSearch.length) {
			return objects;
		}

		return objects.filter((object) =>
			fields.some((key) => {
				const value = get(object, key)?.toString?.()?.toLowerCase?.();
				return value?.includes(normalizedSearch);
			}),
		);
	}, [normalizedSearch, objects, fields]);

	return result;
}
