import React from 'react';

import { useSelector, useStore } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
// import Group from '@asteria/component-core/group';
import { Text, Title } from '@asteria/component-core/typography';
import { statusClasses } from '@asteria/component-core/utils';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Chip from '@asteria/component-chip';
import Contenter from '@asteria/component-tools/contenter';

// import { useFeature } from '@asteria/component-tools/featureflag';
import * as InvoiceStore from '@asteria/datalayer/stores/invoices';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { findScrollingParent } from '@asteria/utils-funcs/node';
import useConfig from '@asteria/utils-hooks/useConfig';

import { Action } from '../../../components/Actions';
import { BatchRemoveButton } from '../../../components/Invoices/BatchRemoveModal';
import Stepper from '../../../components/Stepper';
import LayoutContext from '../../../layout/context';

import './styles.scss';

const BatchSummary = (props) => {
	const { id, onAction, onSubmit } = props;

	const config = useConfig(['pages.invoices.batch.form.confirmation'], {
		deep: true,
	});

	const [checkedFields, setCheckedFields] = React.useState({});

	React.useEffect(() => {
		setCheckedFields(
			(config?.fieldSections ?? [])
				.flatMap(({ grids }) => grids.flatMap(({ fields }) => fields))
				.reduce((acc, object) => ({ ...acc, [object]: false }), {}),
		);
	}, [id]);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'batch:form:copy') {
				setCheckedFields((prev) => {
					const next = { ...prev, [data]: true };

					if (Object.values(next).every((value) => value)) {
						onAction?.('batch:form:complete', id);
					}

					return next;
				});
			}
		},
		[id, onAction],
	);

	const batch = useSelector(
		(store) => InvoiceStore.selectors.batch(store, id),
		(a, b) => isEqual(a, b),
	);

	return (
		<>
			<div className="asteria-component__invoice-batch__fields-sections">
				{(config?.fieldSections ?? []).map(({ name, grids }) => (
					<div
						key={name}
						className="asteria-component__invoice-batch__fields-section"
					>
						<Text size="lg">
							{TranslationService.get([
								'invoice.batch.form.confirmation.fields.section.title',
								`invoice.batch.form.confirmation.fields.section.${name}.title`,
							])}
						</Text>
						{(grids ?? []).map(({ size, fields }, index) => (
							<BatchFields
								key={[name, index].join('-')}
								onAction={handleAction}
								onSubmit={onSubmit}
								fields={fields}
								checkedFields={checkedFields}
								size={size}
								data={batch?.data}
							/>
						))}
					</div>
				))}
			</div>

			<Text size="lg" separator>
				{TranslationService.get([
					'page.introduction.separator.recommended',
					'invoice.batch.form.separator.recommended',
					'invoice.batch.form.confirmation.separator.recommended',
				])}
			</Text>

			<div className="asteria-component__actions">
				<Action
					id="invoice-batch-complete"
					variant="link"
					onAction={onAction}
				/>
			</div>
		</>
	);
};

BatchSummary.displayName = 'BatchSummary';
BatchSummary.propTypes = {
	id: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

const BatchField = (props) => {
	const { name, data, onAction, checked } = props;

	const onCopy = React.useCallback(() => {
		navigator.clipboard.writeText(
			TranslationService.get(
				[
					'invoice.batch.form.field.content',
					'invoice.batch.form.confirmation.field.content',
					`invoice.batch.form.field.${name}.content`,
					`invoice.batch.form.confirmation.field.${name}.content`,
					'invoice.batch.form.field.content.copied',
					'invoice.batch.form.confirmation.field.content.copied',
					`invoice.batch.form.field.${name}.content.copied`,
					`invoice.batch.form.confirmation.field.${name}.content.copied`,
				],
				null,
				data,
			),
		);

		onAction?.('batch:form:copy', name);
	}, [name, data, onAction]);

	const buttonTooltip = React.useMemo(
		() => ({
			variant: 'alt',
			tooltip: TranslationService.get(
				[
					'invoice.batch.form.field.action.copy.tooltip',
					'invoice.batch.form.confirmation.field.action.copy.tooltip',
					`invoice.batch.form.field.${name}.action.copy.tooltip`,
					`invoice.batch.form.confirmation.field.${name}.action.copy.tooltip`,
				],
				null,
				data,
			),
		}),
		[name, data],
	);

	return (
		<div
			className={cn(
				'asteria-component__invoice-batch__field',
				{ [`asteria--batch-field-${name}`]: name },
				statusClasses({ success: checked }),
			)}
		>
			<div
				className={cn(
					'asteria-component__invoice-batch__field-section',
					'asteria--type-label',
				)}
			>
				<Text size="sm">
					{TranslationService.get([
						'invoice.batch.form.field.label',
						'invoice.batch.form.confirmation.field.label',
						`invoice.batch.form.field.${name}.label`,
						`invoice.batch.form.confirmation.field.${name}.label`,
					])}
				</Text>
			</div>
			<div
				className={cn(
					'asteria-component__invoice-batch__field-section',
					'asteria--type-content',
				)}
			>
				<Title size="xxs">
					{TranslationService.get(
						[
							'invoice.batch.form.field.content',
							'invoice.batch.form.confirmation.field.content',
							`invoice.batch.form.field.${name}.content`,
							`invoice.batch.form.confirmation.field.${name}.content`,
						],
						null,
						data,
					)}
				</Title>
			</div>
			<div
				className={cn(
					'asteria-component__invoice-batch__field-section',
					'asteria--type-action',
				)}
			>
				<Button
					icon="content"
					onClick={onCopy}
					tooltip={buttonTooltip}
				/>
			</div>
		</div>
	);
};

BatchField.displayName = 'BatchField';
BatchField.propTypes = {
	name: PropTypes.string,
	data: PropTypes.object,
	onAction: PropTypes.func,
	checked: PropTypes.bool,
};

const BatchFields = (props) => {
	const { fields, size, data, onAction, checkedFields } = props;

	const style = React.useMemo(() => ({ '--size': size }), [size]);

	return (
		<div className="asteria-component__invoice-batch__fields">
			<div
				className="asteria-component__invoice-batch__fields-grid"
				style={style}
			>
				{(fields ?? []).map((key) => (
					<BatchField
						key={key}
						name={key}
						data={data}
						checked={checkedFields[key]}
						onAction={onAction}
					/>
				))}
			</div>
		</div>
	);
};

BatchFields.displayName = 'BatchFields';
BatchFields.propTypes = {
	fields: PropTypes.arrayOf(PropTypes.string),
	size: PropTypes.number,
	data: PropTypes.object,
	onAction: PropTypes.func,
	checkedFields: PropTypes.object,
};

const BatchConfirmationPage = (props) => {
	const { className } = props;

	const { onAction, onSubmit } = React.useContext(LayoutContext);

	const params = useParams();
	const location = useLocation();
	const store = useStore();

	// const hasFAQFeature = useFeature('freja-faq');

	const id = location?.state?.id ?? params?.id;

	const [batchId, setBatchId] = React.useState([].concat(id)[0]);

	const [loading, setLoading] = React.useState(false);

	const batches = useSelector(
		(store) => InvoiceStore.selectors.batches(store, { id: id }),
		(a, b) => isEqual(a, b),
	);

	const batch = useSelector(
		(store) => InvoiceStore.selectors.batch(store, batchId),
		(a, b) => isEqual(a, b),
	);

	const config = useConfig(['pages.invoices.batch.form.confirmation'], {
		deep: true,
	});

	// const handleFAQClick = React.useCallback(
	// 	() => onAction?.('go', '/faq'),
	// 	[onAction],
	// );

	// const handleSupportClick = React.useCallback(
	// 	() => onAction?.('go', '/support'),
	// 	[onAction],
	// );

	const onCancel = React.useCallback(() => onAction?.('go', '/'), [onAction]);

	const onBack = React.useCallback(() => onAction?.('go', -1), [onAction]);

	const onDone = React.useCallback(
		async (options) => {
			setLoading(true);

			await onAction?.('invoices:batch:complete', batch);

			setLoading(false);

			if (!options?.skipRedirect) {
				const [next] = InvoiceStore.selectors.batches(
					store.getState(),
					{
						id: id,
						status: 'SENT',
					},
				);

				if (next?.id) {
					setBatchId(next?.id);
				} else {
					if (Array.isArray(id)) {
						return onAction?.('go', {
							path: `/invoices/batch/multi/success`,
							state: { id: id },
						});
					}

					return onAction?.('go', `/invoices/batch/${id}/success`);
				}
			}
		},
		[batch, id, onAction, store],
	);

	const contenterData = React.useMemo(() => ({ batch: batch }), [batch]);

	const contenterActions = React.useMemo(
		() => ({ onAction: onAction, onSubmit: onSubmit }),
		[onAction, onSubmit],
	);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'invoice-batch-complete') {
				return onDone?.();
			}

			if (action === 'batch:form:complete') {
				return onDone?.({ skipRedirect: true });
			}

			if (action === 'invoices:batch:remove') {
				onCancel?.();
			}

			return onAction?.(action, data);
		},
		[onAction, onCancel, onDone],
	);

	const ref = React.useRef(null);

	React.useLayoutEffect(() => {
		const node = findScrollingParent(ref.current);

		if (node) {
			node.scroll({ behavior: 'smooth', top: 0 });
		}
	}, []);

	return (
		<div
			className={cn(
				'asteria-page',
				'asteria-page__invoices-batch',
				'asteria--variant-confirmation',
				className,
			)}
			ref={ref}
		>
			<Stepper onAction={onAction} onSubmit={onSubmit} />
			<div className="asteria-page__wrapper">
				<Wrapper scroll>
					<Header>
						<Title size="page-title">
							{TranslationService.get(
								[
									'invoice.batch.form.title',
									'invoice.batch.form.confirmation.title',
								],
								undefined,
								{ batch: batch },
							)}
						</Title>
					</Header>
					<Content scroll>
						<div className="asteria-page__placeholder">
							<Contenter
								content={config?.content}
								data={contenterData}
								actions={contenterActions}
							/>
							<Text>{batch?.reference}</Text>
							{Array.isArray(id) ? (
								<div className="asteria-component__invoice-batch__currencies">
									<Text>
										{TranslationService.get(
											'invoice.batch.form.selector.currencies',
										)}
									</Text>
									{batches.map((batch) => {
										const isCompleted = batches.some(
											(object) =>
												object.status === 'COMPLETED' &&
												object.id === batch.id,
										);

										return (
											<Chip
												key={batch?.id}
												size="sm"
												className={cn({
													'asteria-state-completed':
														isCompleted,
												})}
												label={batch?.data?.currency}
												active={batch?.id === batchId}
												icon={
													isCompleted
														? 'check'
														: undefined
												}
												onClick={
													isCompleted
														? undefined
														: () => {
																setBatchId(
																	batch?.id,
																);
														  }
												}
											/>
										);
									})}
								</div>
							) : null}
							<BatchSummary
								id={batchId}
								onAction={handleAction}
								onSubmit={onSubmit}
							/>
						</div>
					</Content>
					<Footer>
						<FooterSection position="first">
							<Button
								variant="tertiary"
								label={TranslationService.get(
									[
										'button.cancel',
										'action.cancel',
										'invoice.batch.form.cancel',
										'invoice.batch.form.confirmation.cancel',
									],
									undefined,
									{ batch: batch },
								)}
								onClick={onCancel}
							/>
							<BatchRemoveButton
								id={id}
								onAction={handleAction}
								onSubmit={onSubmit}
							/>
						</FooterSection>
						<FooterSection position="last">
							<Button
								variant="secondary"
								label={TranslationService.get(
									[
										'button.back',
										'action.back',
										'invoice.batch.form.back',
										'invoice.batch.form.confirmation.back',
									],
									undefined,
									{ batch: batch },
								)}
								onClick={onBack}
							/>
							<Button
								variant="primary"
								label={TranslationService.get(
									[
										'button.continue',
										'action.continue',
										'invoice.batch.form.continue',
										'invoice.batch.form.confirmation.continue',
									],
									undefined,
									{ batch: batch },
								)}
								onClick={onDone}
								disabled={loading}
								loading={loading}
							/>
						</FooterSection>
					</Footer>
				</Wrapper>

				{/*
				<div className="asteria-component__invoice-batch__help">
					<Text size="lg" separator>
						{TranslationService.get([
							'onboarding.linear.help.title',
							'invoice.batch.form.help.title',
							'invoice.batch.form.settings.help.title',
						])}
					</Text>
					<Group verticalAlign="center" horizontalAlign="center">
						{hasFAQFeature ? (
							<Button
								variant="link"
								size="lg"
								label={TranslationService.get([
									'onboarding.linear.help.faq',
									'invoice.batch.form.help.faq',
									'invoice.batch.form.settings.help.faq',
								])}
								onClick={handleFAQClick}
							/>
						) : null}

						<Button
							variant="link"
							size="lg"
							label={TranslationService.get([
								'onboarding.linear.help.support',
								'invoice.batch.form.help.support',
								'invoice.batch.form.settings.help.support',
							])}
							onClick={handleSupportClick}
						/>
					</Group>
				</div>
				 */}
			</div>
		</div>
	);
};

BatchConfirmationPage.displayName = 'BatchConfirmationPage';

BatchConfirmationPage.propTypes = { className: PropTypes.string };

export default BatchConfirmationPage;
