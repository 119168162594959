import React from 'react';

import { useFormContext } from 'react-hook-form';

import { useInfiniteQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import Table, { TableHeader, TableRow } from '@asteria/component-core/table';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import Chip from '@asteria/component-chip';
import Form, {
	Datepicker,
	Input,
	useDebounceFormValues,
	useFormValues,
} from '@asteria/component-form';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import { findScrollingParent } from '@asteria/utils-funcs/node';

import MessageModal from '../../components/PendingInvoices/MessageModal';
import LayoutContext from '../../layout/context';

import { COLUMNS, STATUSES } from './constants';
import { useChat } from './hooks';
import Placeholder from './placeholder';
import StatusChips from './status-chips';
// import StatusSelector from './status-selector';
import Cell from './table/cell';
import HeaderCell from './table/filter';

import './styles.scss';

const ResetButton = React.memo(function ResetButton({
	statuses,
	onSelectStatus,
}) {
	const [search, date] = useFormValues({ name: ['search', 'date'] });

	const { reset } = useFormContext();

	const onReset = React.useCallback(() => {
		onSelectStatus?.(null);
		reset(
			{},
			{
				keepDefaultValues: false,
				keepDirty: false,
				keepDirtyValues: false,
				keepErrors: false,
				keepIsSubmitSuccessful: false,
				keepIsSubmitted: false,
				keepIsValid: false,
				keepSubmitCount: false,
				keepTouched: false,
				keepValues: false,
			},
		);
	}, [onSelectStatus, reset]);

	if (!(statuses || search || date)) {
		return null;
	}

	return (
		<Button
			variant="link"
			label={TranslationService.get([
				'invoices.search.reset.label',
				'pending.invoices.search.reset.label',
			])}
			onClick={onReset}
			className="asteria--variant-reset"
		/>
	);
});

ResetButton.propTypes = {
	statuses: PropTypes.bool,
	onSelectStatus: PropTypes.func,
};

const ChipDate = React.memo(function ChipDate() {
	const date = useFormValues({ name: 'date' });

	const { resetField } = useFormContext();

	const onDismiss = React.useCallback(() => {
		resetField('date');
	}, [resetField]);

	if (!date) {
		return null;
	}

	return (
		<Chip
			size="sm"
			label={TranslationService.get(
				[
					date?.startDate || date?.startDate === date?.endDate
						? 'invoices.search.quick.date.start.label'
						: null,
					date?.endDate
						? 'invoices.search.quick.date.end.label'
						: null,
					date?.startDate &&
					date?.endDate &&
					date?.startDate !== date?.endDate
						? 'invoices.search.quick.date.label'
						: null,
				],
				undefined,
				{ startDate: date?.startDate, endDate: date?.endDate },
			)}
			dismiss
			onDismiss={onDismiss}
			active
		/>
	);
});

const Filters = React.memo(function Filters({
	statuses,
	onSelectStatus,
	onSubmit,
}) {
	return (
		<div className="asteria-component__quick-filters">
			<Input
				name="search"
				icon="magnifier"
				placeholder={TranslationService.get([
					`page.reports.table.quick.search.placeholder`,
				])}
				iconSize="md"
			/>
			<Datepicker name="date" variant="range" skipVisibleValue iconOnly />

			<div className="asteria-component__quick-filters-chips">
				<ChipDate />
				<StatusChips
					selected={statuses}
					onSubmit={onSubmit}
					onSelect={onSelectStatus}
				/>
			</div>
			<ResetButton
				statuses={!!statuses.length}
				onSelectStatus={onSelectStatus}
			/>
		</div>
	);
});

Filters.propTypes = {
	statuses: PropTypes.arrayOf(PropTypes.string),
	onSelectStatus: PropTypes.func,
	onSubmit: PropTypes.func,
};

const FormContent = React.memo(function FormContent({ onAction, onSubmit }) {
	const ref = React.useRef(null);

	const [statuses, setStatuses] = React.useState([]);

	const [sort, setSort] = React.useState({
		field: 'createdAt',
		direction: 'DESC',
	});

	const search = useDebounceFormValues({ name: 'search', delay: 500 });
	const date = useDebounceFormValues({ name: 'date', delay: 1_000 });

	const hasChat = useChat();

	const { data, fetchNextPage, isFetching, isRefetching } = useInfiniteQuery({
		queryKey: [
			'pending',
			'layouts',
			{ sort: sort },
			{ statuses: statuses },
			{ search: search },
			{ date: date },
		],
		queryFn: async ({ pageParam }) => {
			const direction = sort?.direction === 'ASC' ? 'first' : 'last';

			const filters = {};

			if (statuses.length) {
				filters.status = statuses;
			} else {
				filters.status = STATUSES;
			}

			if (search) {
				filters.search = search;
			}

			if (date?.startDate) {
				filters.startDate = date.startDate;
			}

			if (date?.endDate) {
				filters.endDate = date.endDate;
			}

			return onSubmit?.('invoice-layout:list', {
				raw: true,
				skipPagination: true,
				skipInvalidate: true,
				skipDispatch: true,
				filters: filters,
				pageFilters: {
					...pageParam,
					[direction]: 100,
					orderField: sort?.field,
				},
				fields: `
					_id
					companyId
					partnerId
					status
					createdAt
					pdfUri
					messages {
						id
						userId
						userType
						message
						read
						createdAt
						updatedAt
					}
					invoices {
						meta { invoiceNumber }
					}
	`,
			});
		},
		getNextPageParam: (lastPage) => {
			if (sort?.direction === 'ASC') {
				if (lastPage?.pageInfo?.hasNextPage) {
					return { after: lastPage?.pageInfo?.endCursor };
				}
			} else {
				if (lastPage?.pageInfo?.hasPreviousPage) {
					return { before: lastPage?.pageInfo?.startCursor };
				}
			}
		},
		initialPageParam: {},
		// refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,

		keepPreviousData: true,

		retry: false,
	});

	const layouts = (data?.pages ?? [])
		.flatMap(({ edges }) => edges ?? [])
		.map(({ node }) => node);

	const last = layouts.slice(-5)[0];
	const lastId = last?._id ?? last?.id;

	const columns = React.useMemo(
		() =>
			COLUMNS.filter(({ name }) => {
				if (name === 'options' || name === 'messages') {
					return hasChat;
				}

				return true;
			}),
		[hasChat],
	);

	React.useLayoutEffect(() => {
		function callback(entities) {
			for (const entity of entities) {
				if (entity.isIntersecting) {
					fetchNextPage?.();
				}
			}
		}

		if (ref.current) {
			const node = ref.current?.querySelector?.(
				`[data-id="${lastId}"] > .asteria-component__table-cell`,
			);

			if (node) {
				let options = {
					root: findScrollingParent(ref.current),
					rootMargin: '0px',
					threshold: 1.0,
				};

				const observer = new IntersectionObserver(callback, options);
				observer.observe(node);

				return () => {
					observer.unobserve(node);
				};
			}
		}
	}, [fetchNextPage, lastId]);

	const onSelectStatus = React.useCallback((status) => {
		setStatuses((statuses) => {
			if (status === null) {
				return [];
			}

			const index = statuses.indexOf(status);

			let updated = [...statuses];

			if (index === -1) {
				updated = statuses.concat(status);
			} else {
				updated.splice(index, 1);
			}

			return updated;
		});
	}, []);

	const onSort = React.useCallback((data) => {
		setSort((current) => {
			if (current.field === data) {
				const direction = current.direction === 'DESC' ? 'ASC' : 'DESC';

				return { field: data, direction: direction };
			}

			return { field: data, direction: 'ASC' };
		});
	}, []);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'table:filter') {
				return onSort(data);
			}

			return onAction?.(action, data);
		},
		[onAction, onSort],
	);

	return (
		<>
			<Filters
				statuses={statuses}
				onSubmit={onSubmit}
				onAction={onAction}
				onSelectStatus={onSelectStatus}
			/>

			<div className="asteria-page__invoices__content">
				<Table
					className={cn(
						'asteria-page__invoices-table',
						`asteria--columns-${columns?.length}`,
						{ ['asteria--has-chat']: hasChat },
					)}
					onAction={onAction}
					ref={ref}
				>
					<TableHeader>
						{columns.map((field) => (
							<HeaderCell
								key={`table-header-${field.name}`}
								sort={sort}
								field={field}
								onAction={handleAction}
							/>
						))}
					</TableHeader>

					{layouts.length ? (
						layouts.map((object) => (
							<TableRow
								key={object?._id ?? object.id}
								data-id={object?._id ?? object.id}
							>
								{columns.map(({ name, format }) => (
									<Cell
										key={`table-${name}`}
										name={name}
										data={object}
										onAction={handleAction}
										format={format}
									/>
								))}
							</TableRow>
						))
					) : !isFetching || isRefetching ? (
						<TableRow className="asteria--type-empty">
							<Cell empty />
						</TableRow>
					) : null}

					{isFetching && !isRefetching ? <Placeholder /> : null}
				</Table>
			</div>
		</>
	);
});

FormContent.propTypes = { onAction: PropTypes.func, onSubmit: PropTypes.func };

const PendingInvoices = React.memo((props) => {
	const { className } = props;

	const { onAction, onSubmit } = React.useContext(LayoutContext);

	const [modalId, setModalId] = React.useState(null);

	const onMessageModalOpen = React.useCallback((id) => {
		setModalId(id);
	}, []);

	const onMessageModalClose = React.useCallback(() => {
		setModalId(null);
	}, []);

	const handleAction = React.useCallback(
		(action, data) => {
			if (action === 'open:message') {
				return onMessageModalOpen(data);
			}

			if (action === 'close:message') {
				return onMessageModalClose();
			}

			return onAction?.(action, data);
		},
		[onMessageModalClose, onAction, onMessageModalOpen],
	);

	return (
		<>
			<div
				className={cn(
					'asteria-page',
					'asteria-page__pending-invoices',
					className,
				)}
			>
				<div key="content" className="asteria-page__wrapper">
					<Group direction="horizontal">
						<TextGroup>
							<Title size="page-title">
								{TranslationService.get([
									`page.invoices.pending.title`,
									`page.invoices.pending.details.title`,
								])}
							</Title>
							<Translation
								translationKey={[
									`page.invoices.pending.content`,
									`page.invoices.pending.details.content`,
								]}
								Component={Text}
								size="lg"
							/>
						</TextGroup>
						{/* <StatusSelector
							selected={statuses}
							onSelect={onSelectStatus}
						/> */}
					</Group>

					<Form>
						<FormContent
							onAction={handleAction}
							onSubmit={onSubmit}
						/>
					</Form>
				</div>
			</div>

			{modalId ? (
				<MessageModal
					id={modalId}
					type="invoices"
					onAction={handleAction}
					onSubmit={onSubmit}
				/>
			) : null}
		</>
	);
});

PendingInvoices.displayName = 'PendingInvoices';

PendingInvoices.propTypes = { className: PropTypes.string };

export default PendingInvoices;
